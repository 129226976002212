<template>
  <div
    v-if="isHshow"
  >
    <base-material-card
      title="项目推送会商"
      icon="mdi-file-search"
      class="mt-12 pr-0"
    >
      <template
        v-if="isadd"
        v-slot:after-heading2
      >
        <div style="position:absolute;right:0px">
          <v-btn
            color="#0D47A1"
            small
            @click="add()"
          >
            新增意见
          </v-btn>
        </div>
      </template>
      <v-col
        cols="12"
        class="pa-2 hsbox"
      >
        <v-card
          v-for="(item,inx) in pushDataList"
          :key="inx"
          style="width:100%;position: relative;"
          class="pa-4 mt-10"
          outlined
        >
          <v-btn
            color="#C4E3F5"
            dark
            class="cenbtn"
            style="position: absolute;left: 20px;top: -30px;"
          >
            <h3
              style="font-family:Helvetica"
              class="mb-0"
            >
              第{{ pushDataList.length - inx }}轮次
            </h3>
          </v-btn>
          <div
            v-if="orgValue === 'ROLE_TZCJCNBYJ'&& item.data.isBack"
            style="width:100%"
          >
            待完善原因：<h3 style="color:red;display:inline">
              {{ item.data.reason }}
            </h3>
          </div>
          <div
            v-if="orgValue === 'ROLE_KFQCNBYJ'&& item.data.isBack1"
            style="width:100%"
          >
            待完善原因：<h3 style="color:red;display:inline">
              {{ item.data.reason }}
            </h3>
          </div>
          <div
            v-if="orgValue === 'ROLE_TZCJCFZRSHYJ' && item.data.isBack2"
            style="width:100%"
          >
            待完善原因：<h3 style="color:red;display:inline">
              {{ item.data.reason }}
            </h3>
          </div>
          <v-card
            v-if="item.data && item.data.tzcOpinion"
            class="pa-2  d-flex mt-2 mb-0"
            style="justify-content: space-between;"
            outlined
          >
            <div>
              <p class="ma-0">
                <b class="red--text text--darken-4">
                  项目拟推送{{ tsname }}：
                </b>
                <span>{{ item.data.park?item.data.park:'暂不推送' }}</span>
              </p>
              <p class="ma-0">
                <b class="red--text text--darken-4">
                  投资促进处拟办意见 ：
                </b>
                <span>{{ item.data.tzcOpinion ? item.data.tzcOpinion:'无' }}</span>
              </p>
            </div>
            <v-menu
              v-if="orgValue === 'ROLE_TZCJCNBYJ'"
              offset-y
              :close-on-content-click="false"
            >
              <template v-slot:activator="{ on }">
                <v-avatar
                  size="36"
                  v-on="on"
                >
                  <v-icon style="cursor:pointer">
                    mdi-dots-vertical
                  </v-icon>
                </v-avatar>
              </template>
              <v-list>
                <v-list-item
                  style="cursor:pointer"
                  @click="add(item)"
                >
                  <v-icon
                    color="#2196f3"
                    small
                    class="mr-1"
                  >
                    mdi-pencil
                  </v-icon>
                  <span style="color:#2196f3">编辑</span>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-card>
          <v-card
            v-if="item.data && item.data.kfqOpinion "
            class="pa-2  d-flex mt-2 mb-0"
            style="justify-content: space-between;"
            outlined
          >
            <p class="ma-0">
              <b class="red--text text--darken-4">
                开发区处拟办意见 ：
              </b>
              <span>{{ item.data.kfqOpinion ?item.data.kfqOpinion :'无' }}</span>
            </p>
            <!-- isBack 上一个重新填了 -->
            <v-menu
              v-if="orgValue === 'ROLE_KFQCNBYJ'&& !item.data.isBack"
              offset-y
              :close-on-content-click="false"
            >
              <template v-slot:activator="{ on }">
                <v-avatar
                  size="36"
                  v-on="on"
                >
                  <v-icon style="cursor:pointer">
                    mdi-dots-vertical
                  </v-icon>
                </v-avatar>
              </template>
              <v-list>
                <v-list-item
                  style="cursor:pointer"
                  @click="add(item)"
                >
                  <v-icon
                    color="#2196f3"
                    small
                    class="mr-1"
                  >
                    mdi-pencil
                  </v-icon>
                  <span style="color:#2196f3">编辑</span>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-card>
          <v-card
            v-if="item.data && item.data.tzcfzrOpinion"
            class="pa-2  d-flex mt-2 mb-0"
            style="justify-content: space-between;"
            outlined
          >
            <div>
              <p class="ma-0">
                <b class="red--text text--darken-4">
                  项目推送{{ tsname }} ：
                </b>
                <span>{{ item.data.park1?item.data.park1:'暂不推送' }}</span>
              </p>
              <p class="ma-0">
                <b class="red--text text--darken-4">
                  投资促进处负责人审核意见：
                </b>
                <span>{{ item.data.tzcfzrOpinion?item.data.tzcfzrOpinion:'无' }}</span>
              </p>
            </div>
            <!-- isBack 上一个重新填了 -->
            <v-menu
              v-if="orgValue === 'ROLE_TZCJCFZRSHYJ' && !item.data.isBack"
              offset-y
              :close-on-content-click="false"
            >
              <template v-slot:activator="{ on }">
                <v-avatar
                  size="36"
                  v-on="on"
                >
                  <v-icon style="cursor:pointer">
                    mdi-dots-vertical
                  </v-icon>
                </v-avatar>
              </template>
              <v-list>
                <v-list-item
                  style="cursor:pointer"
                  @click="add(item)"
                >
                  <v-icon
                    color="#2196f3"
                    small
                    class="mr-1"
                  >
                    mdi-pencil
                  </v-icon>
                  <span style="color:#2196f3">编辑</span>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-card>
        </v-card>
      </v-col>
      <pushtracks
        ref="pushtracks"
        :parent-id="parentId"
        :form-id="formId"
        @pushInitChild="pushInitChild()"
      />
    </base-material-card>
  </div>
</template>

<script>
  export default {
    components: {
      pushtracks: () => import('./Pushtracks'),
    },
    props: {
      formId: {
        type: String,
        default: null,
      },
      // 项目父级id
      parentId: {
        type: String,
        default: null,
      },
    },
    data () {
      return {
        round: '',
        opinions: '',
        parkCode: [],
        // 外商
        wsFormId: 'bce0f632-42a8-4cf7-a30c-e5c6f00c6e62',
        // 推介
        tjFormId: 'defdcd74-fe79-4906-b3aa-dbdb0ce55afe',
        orgList: [],
        replaceFields: { title: 'name', value: 'id' },
        userGroupTree: [],
        userSelect: false,
        desserts: [],
        parentOrgId: '',
        allGroupTree: [],
        detailList: {},
        // 会商意见表(共用)
        searchParame: {
          formId: 'd3ced02a-aee1-48dc-9a54-93323876d62c',
          _from: 'public',
          page: 0,
        },
        orgValue: '',
        orgName: '项目推送会商',
        isadd: false,
        edit: false,
        isHshow: false,
        // 总会商意见
        pushDataList: [],
        tsname: '市商务（投促）部门',
        respData: [],
      }
    },
    async mounted () {
      await this.getdb()
      // 根据父级传的formId更换相应推送列表
      if (this.formId === this.wsFormId) {
        this.tsname = '市商务（投促）部门'
      } else if (this.formId === this.tjFormId) {
        this.tsname = '推送目标'
      }
      // 父级项目信息
      await this.getDetail()
      // 个人信息
      await this.getMeInfo()
      // 获取会商意见表
      await this.getOpinion()
    },
    methods: {
      async pushInitChild () {
        // 更新会商信息
        await this.getOpinion()
        // 更新下详情中的推送，步骤条
        await this.$emit('pushInit')
      },
      add (item) {
        this.$refs.pushtracks.open('two', item)
        this.userSelect = true
      },
      async getMeInfo () {
        const res = await this.$axios.get('/me')
        console.log('res', res)
        const roleName = res.data.data.roles
        // 投资促进处负责人
        if (roleName.includes('ROLE_TZCJCFZRSHYJ')) {
          this.orgValue = 'ROLE_TZCJCFZRSHYJ'
          this.orgName = '投资促进处负责人审核意见'
          this.isHshow = true
        // 开发区处
        } else if (roleName.includes('ROLE_KFQCNBYJ')) {
          this.orgValue = 'ROLE_KFQCNBYJ'
          this.orgName = '开发区处拟办意见'
          this.isHshow = true
        // 投资促进处
        } else if (roleName.includes('ROLE_TZCJCNBYJ')) {
          this.orgValue = 'ROLE_TZCJCNBYJ'
          this.orgName = '投资促进处拟办意见'
          this.isHshow = true
        } else {
          this.orgValue = ''
          this.isHshow = false
        }
        console.log('this.orgValue', this.orgValue)
      },
      async getDetail () {
        const resp = await this.$axios.get(
          '/reports/' + this.parentId + '/detail',
        )
        console.log('resp', resp)
        this.detailList = resp.data.data
      },
      // 获取代表处字典
      async getdb () {
        const resp = await this.$axios.get('/dictionaries/e4f801f6-2c03-4575-aa9b-27bc091a444f')
        const respData = resp.data.data
        this.respData = respData
      },
      // 代表处转换
      async changedb (name) {
        const names = name.split(',')
        for (let inx = 0; inx < this.respData.length; inx++) {
          for (let j = 0; j < names.length; j++) {
            if (this.respData[inx].code === names[j]) {
              names.splice(names[j], 1, this.respData[inx].name)
            }
          }
        }
        return names.join()
      },
      async getOpinion () {
        const searchParame = this.searchParame
        searchParame.parentID = this.parentId
        const resp = await this.$axios.get('/reports/query', {
          params: searchParame,
        })
        const desserts = resp.data.data.content
        desserts.map(async val => {
          if (val.data.park) {
            this.$set(val.data, 'park', await this.changedb(val.data.park))
          }
          if (val.data.park1) {
            this.$set(val.data, 'park1', await this.changedb(val.data.park1))
          }
        })
        this.pushDataList = desserts
        console.log('desserts', desserts);

        if (desserts.length) {
          // 最新一轮
          const list = desserts[0].data
          // 投资促进处  开启下一轮 待完善不能开启
          if (!list.isBack && !list.isBack2 && list.tzcOpinion && list.tzcfzrOpinion && this.orgValue === 'ROLE_TZCJCNBYJ') {
            this.isadd = true
          // 投资促进处负责人
          } else if (
            list.tzcOpinion && !list.tzcfzrOpinion &&
            this.orgValue === 'ROLE_TZCJCFZRSHYJ'
          ) {
            this.isadd = true
          // 开发区处
          } else if (
            list.tzcOpinion &&
            !list.kfqOpinion &&
            this.orgValue === 'ROLE_KFQCNBYJ'
          ) {
            this.isadd = true
          } else {
            this.isadd = false
          }

        // 投资促进处
        } else if (this.orgValue === 'ROLE_TZCJCNBYJ') {
          this.isadd = true
        } else {
          this.isadd = false
        }
        // if (this.detailList.status !== 10) {
        //   this.isadd = false
        // }
        console.log('desserts', desserts)
      },
    },
  }
</script>
<style scoped lang='scss'>
::v-deep.v-dialog__content {
  justify-content: flex-end !important;
}
::v-deep .v-dialog {
  height: 86% !important;
}
::v-deep .fildName .v-text-field__details {
  display: none;
}
::v-deep .v-data-table__wrapper {
  max-height: 350px;
}
.tscNum ::v-deep .v-input__slot {
  border: 1px solid #d9d9d9;
  min-height: 33px;
}
.hsbox{
  max-height: 400px;
  overflow: auto;
}
</style>
